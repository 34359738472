;(function (root, factory)
{
	root['LiveNow'] = factory();
}(this, function ()
{
	function LiveNow(config)
	{
		var liveNowTmep = '<div class="program channel-type-2 {resourceIdclass} item {selectClass} channel-neu-{neulionAccess}" data-neuaccess="{neulionAccess}" data-accesstype="{accessType}" data-resourceid="{resourceId}" data-id="{id}" data-seoname="{seoname}" data-seoalias="{seoAlias}"><div class="program__header-wrap">{name}</div><div class="program-wrap"><div class="program__img-wrap">' +
				'<a onclick="handlePlayChannel(\'{seoname}\', this)" class="program__img-content"><img class="img-responsive program__img" src="{image}" alt="{title}" title="{title}" style="opacity: 1;"><div class="program__mask">' +
				'<span class="program__icon program--icon-play">' + NEU.util.getLocalizedString('watching') + '</span></div><span class="js-channel-lock program__icon program--icon-lock"></span></a>' +
				'</div><div class="program__detail"><a onclick="handlePlayChannel(\'{seoname}\', this)" class="program__title program__title--size-small whats-program__title">{title}</a>' +
				'<div class="program__info program__info--size-big program__info--font-regular whats-program__info--margin"><span class="program__duration">{time}</span>' +
				'</div></div></div></div>';
		var container = '<div class="epg-current-now owl-carousel owl-theme" data-target="live_now"></div>'

		var owner = this;
		owner.options = $.extend(true, {
			getChannelSuccessCallback: function ()
			{
			},
			renderCallback: function ()
			{
			}
		}, config);
		owner.checkChannelsStateTimer = false;
		owner.channels = {};
		owner.channelStats = {};
		owner.$EPGContainerId = $(owner.options.containerId);
		this.init = function ()
		{
			this.renderLiveNow();
			this.renderLiveNowStats();
		}
		this.renderLiveNow = function ()
		{
			currentChannelHandle(function ()
			{
				window.getChannels(function (data)
				{
					var list = [];
					owner.options.getChannelSuccessCallback && owner.options.getChannelSuccessCallback();
					owner.channels = data;
					data.forEach(function (channel)
					{
						channel.list.forEach(function (item)
						{
							var temp = liveNowTmep.replace(/\{grouping}/gi, item.grouping.split(':')[0])
									.replace(/\{resourceId}/gi, item.resourceId)
									.replace(/\{resourceIdclass}/gi, item.resourceIdclass)
									.replace(/\{seoAlias}/gi, item.seoAlias)
									.replace(/\{selectClass}/, (((owner.options.nowPlayingchannel.seoName || window.currentChannel).toLowerCase() === item.seoname.toLowerCase()) ? 'is-playing' : ''))
									.replace(/\{neulionAccess}/gi, item.neulionAccess)
									.replace(/\{accessType}/gi,item.accessType)
									.replace(/\{seoname}/gi, item.seoname.toLowerCase())
									.replace(/\{id}/gi, item.id)
									.replace(/\{image}/, item.image)
									.replace(/\{title}/gi, item.desc)
									.replace(/\{time}/gi, item.time)
									.replace(/\{name}/, item.name);
							list.push(temp);
						})
					});

					window.handlePlayChannel = function (seoname, _target)
					{
						seoname = seoname.toLowerCase();
						if ($('.program[data-seoname="' + seoname + '"]', owner.$EPGContainerId).hasClass('is-playing'))
						{
							window.nlJumpToLiveProgramCallback && window.nlJumpToLiveProgramCallback();
							return;
						}

						var $parent = $(_target).parents('.program')
						var unlock = $parent.find('.program--icon-lock').is(':hidden');
						if (!unlock)
						{
							if (window.isTVE && userType == 'anonymous') {
								_FreeSample && _FreeSample.check(false,function () {
									owner.options.channelItemClickHandle(seoname)
								});
							} else {
								parent.showAuthPopup($parent.data('accesstype'), $parent.data('resourceid'))
							}
						}
						else
						{
							owner.options.channelItemClickHandle(seoname)
						}
					}
					owner.$EPGContainerId.html($('<div class="epg-current-now owl-carousel owl-theme"/>'));
					$('.epg-current-now.owl-carousel', owner.$EPGContainerId).html(list);
					if (list.length > 0)
					{
						owner.liveNowModal = $('.owl-carousel.epg-current-now', owner.$EPGContainerId).owlCarousel({
							nav: true,
							dots: false,
							margin: 30,
							navText: ['', ''],
							autoWidth: true,
							lazyLoad: true,
							item: 4
						});
						window.updateChannelResourceIds && window.updateChannelResourceIds('.owl-carousel.epg-current-now .program');
					}
					owner.options.renderCallback && owner.options.renderCallback();
				})
			})
		}
		this.renderLiveNowStats = function ()
		{
			owner.getChannelStats(function (data)
			{
				owner.channelStats = data;
				data.forEach(function (channel)
				{
					var $channelItem = $('.epg-current-now .program[data-seoname="' + channel.seoName.toLowerCase() + '"]', owner.$EPGContainerId)
					if ($channelItem.html() != undefined && channel.item)
					{
						$('.program__title', $channelItem).text(channel.item[0].e);
						$('.program__duration', $channelItem).text(countdownHandle(channel.item[0].su, channel.item[0].d));
						if (channel.item[0].img)
						{
							var _image = /^http/gi.test(channel.item[0].img) ? channel.item[0].img : LOC_CONTENT + 'epg/' + channel.item[0].img
							setTimeout(function ()
							{
								$('.program__img', $channelItem).attr('src', _image)
							}, 100);
						}
					}
				});
			})
		}
		this.getChannelStats = function (callback)
		{

			var timer = 10 * 60 * 1000;
			if (!owner.checkChannelsStateTimer)
			{
				owner.checkChannelsStateTimer = setInterval(currentChannelHandle, timer, callback, 'checkStats');
			}
		}
		this.init()
	}

	return LiveNow;
}));
(function (root, factory)
{
	root['EPGSchedule'] = factory();
}(this, function ()
{
	function EPGSchedule(domId, _channel)
	{
		this._channel = $.extend(true, {}, _channel);
		var owner = this;
		var epgFrameTemplate = '<div class="schedule-time slide-container"><ul class="slide-list js-slide-list"><li></li></ul></div><div id="scheduleList" class="schedule-list player-epg owl-theme"></div>',
				epgFrameData = {
					textScheduleTitle: NEU.util.getLocalizedString('schedule'),
					textFilterShow: ''
				},
				epgItemTemplate = '<div class="program purchase-type-0 {{epgStatus}}" data-id="2">' +
						'<div class="program-wrap">' +
						'<div class="program__img-wrap"><a class="program__img-content">' +
						'<img class="img-responsive program__img" src="{{epgImage}}" alt="{{epgTitle}}" title="{{epgTitle}}" style="opacity: 1;">' +
						'<div class="program__mask"><span class="program__icon program--icon-play">{{epgWatch}}</span></div>' +
						'<span class="program__icon program--icon-lock"></span></a></div>' +
						'<div class="program__detail">' +
						'<a class="program__title program__title--size-small whats-program__title" title="{{epgTitle}}">{{epgTitle}}</a>' +
						'<div class="program__info program__info--size-big program__info--font-regular whats-program__info--margin">' +
						'<span class="program__duration stats-dur upcoming-dur"><span class="live">{{epgLive}}</span>&nbsp;<span class="upcoming">{{epgUpNext}}</span><strong class="dot">&nbsp;·&nbsp;</strong>{{epgBeginTime}}</span>' +
						'</div></div></div></div>';

		var epgLoadingTemp = '<div class="epg-tip"><div class="loading"></div></div>',
				epgLoadErrorTemp = '<div class="epg-tip">No Channels</div>';
		var epgOption = {
			classes: {
				listWrapper: 'schedule-list',
				listItem: 'program', // default
				state: {
					itemUpcoming: 'upcoming-program', // default
					itemLive: 'live-program', // default
					itemPlaying: 'is-playing', // default
					itemArchive: 'is-archive', // default
					itemLiveNext: 'next-program' // for hours' limit // default
				}
			},
			template: {
				epgFrameTemplate: epgFrameTemplate,
				epgFrameData: epgFrameData,
				epgItemTemplate: epgItemTemplate,
				epgItemData: handleTemplateData,	// function
				epgLoadingTemplate: epgLoadingTemp,
				epgLoadErrorTemplate: epgLoadErrorTemp
			},
			server: {
				locEPGPrefix: window.LOC_EPG + owner._channel.seoName.toLowerCase() + '/',
				serverDate: owner._channel.date,// channel date
				clientOffsetTime: owner._channel.timeZoneOffset
			},
			player: {
				isReady: 'hasPlayerReady',
				initPlayLive: true,
				hasLiveCompleteCallback: true,
				updateProgram: null
			},
			epgConfig: {
				isScroll: true,
				daysInterval: window.epgDataRange,
				isCarousel: true,
				dvrHours: window.dvrHours,
				carouselConfig: {
					margin: 30,
					autoWidth: true,
					nav: true,
					navText: ['', '']
				},
				clickableItemSubClasses: ['program__title', 'program__mask']
			},
			callback: {
				playProgram: playChannel,
				dateChanged: dateChanged,
				epgScrolledInView: getFristProgramTime
			}
		};
		owner.epgListModal = null;
		owner.isDVRLive = false;
		owner.$containerId = $(domId);
		owner._epgContainer = $('#sideSchedule', owner.$containerId);
		owner.startProgram = null;
		window.lastPlayingProgram = null;
		this.init = function ()
		{
			if (this._channel.type && this._channel.type == 2)
			{
				epgOption.refreshStateIntervalDelay = window.checkPlaylistTime;
				epgOption.classes.state.itemDvr = 'is-archive';
				epgOption.template.epgItemData = handlePlaylistTemplateData;
				epgOption.playListEPGFeed = LOC_EPG + owner._channel.seoName.toLowerCase() + '.json';
				epgOption.server.clientOffsetTime = g_timeZoneOffset
				owner.epgListModal = new PlayListEPG($('#sideSchedule', owner.$containerId), epgOption);
			}
			else
			{
				if(this._channel.type == undefined)
				{
					epgOption.server.locEPGPrefix = window.LOC_EPG + 'export/' + owner._channel.seoName.toLowerCase() + '/';
				}
				var epgContainer = $('#sideSchedule', owner.$containerId);
				epgContainer.empty()
				owner.epgListModal = new EPGList($('#sideSchedule', owner.$containerId), epgOption);
			}
		}

		function handleTemplateData(epgItem)
		{
			var isTodayTime = +new Date(owner._channel.date.getFullYear(), owner._channel.date.getMonth(), owner._channel.date.getDate()) === +new Date(NEU.util.formatDate(epgItem.sl, 'yyyy,M,d'));
			var todayValue = epgItem.index === 0 ? isTodayTime ? NEU.util.getLocalizedString('today') : NEU.util.formatDate(epgItem.sl, NEU.util.getLocalizedString('date_format_short')) : '';
			var _image = !!epgItem.img ? (/^http/gi.test(epgItem.img) ?  epgItem.img : LOC_CONTENT + 'epg/' + epgItem.img) :
										!! epgItem.image ? (/^http/gi.test(epgItem.image) ? epgItem.image : LOC_CONTENT + 'epg/' + epgItem.image) : (LOC_CONTENT + 'channels/' + owner._channel.id + '_eb.jpg');
			var dateGMT = epgItem.su.replace(/\.\d{3}/, "Z");
			var localTimezone = moment.tz.guess();
			var localDate = moment.tz(dateGMT, localTimezone).format();
			return {
				epgBeginTime: NEU.util.formatDate(localDate, 'h:mma'),
				epgTitle: epgItem.e,
				epgLive: NEU.util.getLocalizedString('epg_live'),
				epgUpNext: NEU.util.getLocalizedString('up_next'),
				epgWatch: NEU.util.getLocalizedString('watching'),
				epgToday: todayValue,
				epgImage: _image
			};

		}

		function getFristProgramTime(programs)
		{
			var dateGMT = programs[0].su.replace(/\.\d{3}/, "Z");
			var localTimezone = moment.tz.guess();
			var startProgram = moment.tz(dateGMT, localTimezone).format();
			var _startTime = NEU.util.formatDate(startProgram, 'yyyy,M,d');
			var isTodayTime = NEU.util.formatDate(new Date(), 'yyyy,M,d') === NEU.util.formatDate(startProgram, 'yyyy,M,d');
			var todayValue = isTodayTime ? NEU.util.getLocalizedString('today') : NEU.util.formatDate(startProgram, NEU.util.getLocalizedString('date_format_short'));
			if (owner.startProgram == _startTime)
			{
				return
			}
			owner.startProgram = _startTime;
			var $parent = $('.js-slide-list', owner._epgContainer);
			var list = $parent.find('li')
			var len = list.length

			if (len == 0 || len == 1)
			{
				$parent.append($('<li>' + todayValue + '</li>'))
			}

			var $first = $parent.find('li:first');
			var height = $first.height();
			$parent.find('li:last').html(todayValue);
			$first.animate({
				marginTop: -height + 'px'
			}, 500, function ()
			{
				$first.css('marginTop', 0).appendTo($parent);
			});
		}

		function handlePlaylistTemplateData(epgItem)
		{
			var hasHour = parseInt(epgItem.d / 60) > 59;
			var _img = !!epgItem.img ? LOC_CONTENT + 'epg/' + epgItem.img : LOC_CONTENT + 'channels/' + owner._channel.id + '_eb.jpg';
			return {
				epgBeginTime: addText((hasHour ? (parseInt(epgItem.d / 3600)) : '')) + ':' + addText(parseInt((hasHour ? epgItem.d - parseInt(epgItem.d / 3600) * 3600 : epgItem.d) / 60)) + ':' + addText(epgItem.d % 60),
				epgTitle: epgItem.e,
				epgImagePrefix: LOC_RESOURCE + SITE_ID,
				epgImage: _img,
				epgLive: NEU.util.getLocalizedString('epg_live'),
				epgUpNext: NEU.util.getLocalizedString('up_next'),
				epgWatch: NEU.util.getLocalizedString('watching'),
				epgToday: '',
				epgStatus: epgItem.status
			};
		}

		function addText(num)
		{
			return num !== '' ? num < 10 ? '0' + num : num : '00'
		}

		function dateChanged(data)
		{
			//TODO data change
		}

		function getFormatDate(dateString)
		{
			return dateString.split("-")[0] + '/' + dateString.split("-")[1] + '/' + dateString.split("-")[2].substring(0, 2);
		}

		this.playChannelEpg = function (channel, isLive, isPlaylist, isLiveCompletedChanged) {
			playChannel(channel, isLive, isPlaylist, isLiveCompletedChanged);
		}

		function playChannel(channel, isLive, isPlaylist, isLiveCompletedChanged)
		{
			if(hasHideChannel)
			{
				return
			}
			owner.isDVRLive = isLive || false;
			if (isPlaylist && !isLive)
			{
				getProgramDetail(channel.spid, function (program)
				{
					var newProgram = {};
					newProgram.epgShowName = channel.e;
					newProgram.bigImage =  !!channel.img ? (/^http/gi.test(channel.img) ?  channel.img : LOC_CONTENT + 'epg/' + channel.img) :
							!! channel.image ? (/^http/gi.test(channel.image) ? channel.image : LOC_CONTENT + 'epg/' + channel.image) : (LOC_CONTENT + 'channels/' + owner._channel.id + '_eb.jpg');
					program.bigImage = newProgram.bigImage;
					window.singlePlayer.updateLastProgram && window.singlePlayer.updateLastProgram(newProgram);
					getchannelsRegion(function (_channel) {
						program = preProcessProgram(program);
						program.seoName = owner._channel.seoName;
						window.singlePlayer.playVOD(program)
					});
				});
			}
			else
			{
				var seoname = owner._channel.seoName.toLowerCase();
				var url = setURLParam('seoName', seoname, removeURLParam('seoName'));
				if (window.history.state && window.history.state.seo_name !== seoname) {
					window.top.history.replaceState({type: 'channelPlayer', seo_name: seoname}, null, url);
				}

				window.top.g_channelWaitToPlay = processChannel(jQuery.extend(true, {}, channel));

				var data_date = channel && channel.sl ? getFormatDate(channel.sl) : moment().format('DD/MM/YY');
				var data_index = channel ? channel.index : '';
				var autoSwitch = false;
				if (isLiveCompletedChanged || (window.autoSwitchEPG && window.autoSwitchEPG.sl == channel.sl && window.autoSwitchEPG.index == channel.index))
				{
					autoSwitch = true;
				}

				window.autoSwitchEPG = null;


				if (!owner._channel.noAccess)
				{
					var channel = jQuery.extend(true, {}, channel);
					var channelExt = processChannel(channel);
					setChannelCustomDimension(channelExt);
					getchannelsRegion(function (_channel) {
						window.singlePlayer.playChannel(channelExt);
					})
				}
				else if (isTVE)
				{
					 window.waitFinalStatus(handleChannel);
					// put the channel is waitting for adobepass token into global g_channelWaitToPlay
					// handled in parent setToken method
					function handleChannel(){

						var channelResourceId = getChannelResourceId(window.top.g_channelWaitToPlay.id);
						if (channelResourceId === '')
						{
							var channelExt = processChannel(channel);
							setChannelCustomDimension(channelExt);
							getchannelsRegion(function (_channel) {
								processChannel(channelExt)
								window.singlePlayer.playChannel(channelExt);
								window.lastPlayingProgram = {'data_date': data_date, 'data_index': data_index};
							})
						}
						else
						{
							var mrssData = getCustomResourceId();
							var requestedResourceID = filterResourceId(channelResourceId);
							g_nlAdobePassResourceId = requestedResourceID;
							window.parent.neuap && window.parent.neuap.getToken(window.parent.processMrss && window.parent.processMrss(requestedResourceID, mrssData) || requestedResourceID, function (data) {
								if (!data.token)
								{
									var needExitPlayer = (autoSwitch || !window.lastPlayingProgram) ? true : false;
									if (needExitPlayer)
									{
										try
										{
											window.hasPlayerReady && window.singlePlayer && window.singlePlayer.stopVOD(true);
										}
										catch (e)
										{
											$(".bigPlayBtn").hide();
										}
									}
									programNotAuthorizedHandle(data.errorMessage, needExitPlayer);
									return false;
								}
								else
								{
									if (window.lastPlayingProgram && window.lastPlayingProgram.data_date == data_date && window.lastPlayingProgram.data_index == data_index)
									{
										return false;
									}
									else
									{
										window.lastPlayingProgram = {'data_date': data_date, 'data_index': data_index};
										if (isLiveCompletedChanged)
										{
											return false;
										}
										var publishPointParams = {
											aprid: requestedResourceID,
											aptoken: encodeURIComponent(data.token)
										};
										if (requestedResourceID != data.requestedResourceId)
											publishPointParams.apridrss = encodeURIComponent(data.requestedResourceId);

										if (window.top.g_channelWaitToPlay != null)
										{
											// 'home|channels' for popup player on channels page, 'channel' for deeplink channel page
											// channel player can swtich between vod & channel
											// channel obj is dynamic
											$.extend(window.top.g_channelWaitToPlay.publishPointParams, publishPointParams);
											setChannelCustomDimension(window.top.g_channelWaitToPlay);
											window.singlePlayer.playChannel(window.top.g_channelWaitToPlay);
											window.lastPlayingProgram = {
												'data_date': data_date,
												'data_index': data_index
											};
											window.top.g_channelWaitToPlay = null;
										}
									}
								}
							});

							function getCustomResourceId()
							{
									var customInfo = {};
									if (window.top.g_channelWaitToPlay.e)
									{
										customInfo.assetId = window.top.g_channelWaitToPlay.e;
										customInfo.guid = customInfo.assetId + (window.top.g_channelWaitToPlay.su ? '_' + NEU.formatDate(window.top.g_channelWaitToPlay.su, 'yyyyMMdd-HHmm') : '');
									}
									customInfo.tvRating = window.top.g_channelWaitToPlay.r || '';
									return customInfo;
							}
						}
					}
				}
				else if (isTemporaryUser)
				{
					var publishPointParams = {
						aprid: TEMPRORARY_USER.aprid,
						aptoken: encodeURIComponent(TEMPRORARY_USER.aptoken)
					};
					if(window.top.g_channelWaitToPlay != null){
						// 'home|channels' for popup player on channels page, 'channel' for deeplink channel page
						// channel player can swtich between vod & channel
						// channel obj is dynamic
						$.extend(window.top.g_channelWaitToPlay.publishPointParams, publishPointParams);
						setChannelCustomDimension(window.top.g_channelWaitToPlay);
						window.singlePlayer.playChannel(window.top.g_channelWaitToPlay);
						window.top.g_channelWaitToPlay = null;
					}
				}
			}
			NLTracker.trackPage('/liveplayer');

			function filterResourceId(channelResourceIds)
			{
				//return channelResourceIds.split(',')[0];
				var resourceIdArray = channelResourceIds.split(',');
				for (var index = 0; index < resourceIdArray.length; index++)
				{
					if (parent.userAuth.indexOf('tve-auth-'+resourceIdArray[index]) > -1)
					{
						return resourceIdArray[index];
					}
				}
			}
		}


		function getchannelsRegion(callback)
		{
			if (owner.allChannelsRegion)
			{
				var channel = owner.allChannelsRegion.filter(function (info) {
					return info.seoName === owner._channel.seoName;
				})[0];
				callback && callback(channel);
			}
			else
			{
				NEU.util.getJSON(window.channelsRegion, function (data) {
					owner.allChannelsRegion = data.channels;
					var channel = owner.allChannelsRegion.filter(function (info) {
						return info.seoName === owner._channel.seoName;
					})[0];
					callback && callback(channel);
				}, {round: 60})
			}
		}

		function processChannel(channel)
		{
			if (window.ENABLE_CHANNEL_DRM)
			{
				channel.drm = true;
			}
			processVideo(channel);
			channel.id = owner._channel.id;
			channel.type = 'channel';
			channel.name = owner._channel.name;
			channel.seoName = owner._channel.seoName;
			channel.seoAlias = owner._channel.seoAlias;
			channel.isLive = true;
			channel.isDVRLive = !owner.isDVRLive;
			channel.bigImage = !!channel.img ? (/^http/gi.test(channel.img) ?  channel.img : LOC_CONTENT + 'epg/' + channel.img) :
										!! channel.image ? (/^http/gi.test(channel.image) ? channel.image : LOC_CONTENT + 'epg/' + channel.image) : (LOC_CONTENT + 'channels/' + owner._channel.id + '_eb.jpg');
			channel.image = LOC_CONTENT + 'channels/' + owner._channel.id + '_es.png';
			channel.displayName = channel.name;
			channel.grouping = owner._channel.grouping;
			if (owner.isDVRLive)
			{
				if (!$.isEmptyObject(channel))
				{
					channel.nextDateTimeGMT = channel.eu;
					channel.nextDateTime = channel.el;
				}
			}
			else
			{
				if (!$.isEmptyObject(channel))
				{
					channel.endDateTimeGMT = channel.eu;
					channel.endDateTime = channel.el;
				}
			}
			if (!$.isEmptyObject(channel))
			{
				channel.date = channel.sl;
				channel.beginDateTimeGMT = channel.su;
				channel.epgShowName = channel.e || channel.t || '';
			}
			// FreeWheel
			// channel.fwId = "univision_simulcast";
			// channel.adTag = window.FW_SITE_SECTION_ID;
			// if (channel.publishPointParams == null){
			// 	channel.publishPointParams = {};
			// }
			// channel.publishPointParams.fwcsid = window.FW_SITE_SECTION_ID;
			return channel;
		}

		function getProgramDetail(id, callback)
		{
			NEU.util.getJSON('/service/program?id=' + id + '&format=json', function (data)
			{
				callback && callback(data);
			})
		}

		function preProcessProgram(program)
		{
			processVideo(program);
			program.type = 'video';
			program.isLive = true;
			program.isDVRLive = !owner.isDVRLive;
			program.image = LOC_CONTENT + 'channels/' + owner._channel.id + '_es.png';
			program.bigImage = (/^http/gi.test(program.bigImage)) ? program.bigImage : LOC_CONTENT + program.bigImage;
			program.date = program.releaseDate;
			program.beginDateTimeGMT = program.releaseDateGMT;
			program.runtime = getProgramDuration(program.runtime);
			program.endDateTimeGMT = NEU.util.formatDate(new Date(NEU.util.parseDate(program.beginDateTimeGMT).getTime() + program.runtime * 1000), 'yyyy-MM-ddTHH:mm:ss.000');
			program.name && (program.displayName = program.name);
			return program;
		}

		function getProgramDuration(runtime)
		{
			var dur = 0;
			if (runtime.indexOf(":") > -1)
			{
				dur = parseInt(runtime.split(":")[0], 10) * 60 + parseInt(runtime.split(":")[1], 10);
			}
			else
			{
				dur = parseInt(runtime * 60, 10);
			}
			return dur;
		}

		this.init();
	}

	return EPGSchedule;
}));
(function (root, factory)
{
	root['EPG'] = factory();
}(this, function ()
{

	function PlayerEPG(config)
	{

		var temp = '<div id="epg">' +
				'<div id="epg-container">' +
				'<div class="player-epg">' +
				'<div class="player-epg-header">' +
				'<div class="epg-header-content">' +
				'<div data-target="schedule" class="epg-schedule">' + NEU.util.getLocalizedString('schedule') + '</div>' +
				'<div data-target="live_now" class="epg-live-now ">' + NEU.util.getLocalizedString('epg_live') + '</div>' +
				'<div class="close"></div>' +
				'</div>' +
				'</div>' +
				'<div class="player-epg-wrap">' +
				'<div class="loading"></div>' +
				'<div class="epg-list" id="sideSchedule" data-target="schedule"></div>' +
				'<div class="live-now-wrap" id="epg-current-now" data-target="live_now"></div>' +
				'</div>' +
				'</div>' +
				'</div>' +
				'</div>';

		var defaultConfig = {}, owner = this;
		this.options = $.extend(true, {}, defaultConfig, config);

		this.$containerId = $(this.options.containerId);
		this.epgModal = null;
		this.liveNowModal = null;
		this.hasEPGRender = false;
		this.hasScheduleRender = false;
		this.hasCheckChannelStats = false;

		this.initLiveNow = function ()
		{
			owner.liveNowModal = new window.LiveNow({
				containerId: '#epg-current-now',
				nowPlayingchannel:owner.epgModal._channel,
				getChannelSuccessCallback: function ()
				{
					toggleLoading(false);
				},
				channelItemClickHandle: function (seoname)
				{
					window.switchChannel && window.switchChannel(seoname.toLowerCase())
				}
			})
		}

		this.initEpg = function (channel)
		{
			owner.epgModal = new EPGSchedule(owner.options.containerId, channel);
		}

		this.renderEPGContainer = function ()
		{
			if (!window.g_channel)
			{
				return;
			}
			if (!this.hasEPGRender)
			{
				this.hasEPGRender = true;
				owner.$containerId.append(temp);
			}
			$('.epg-header-content div:not(.close)', owner.$EPGContainerId).on('click', function ()
			{

				var $this = $(this), $target = $this.data('target');

				$this.addClass('active').siblings('div').removeClass('active');
				$('.player-epg-wrap div[data-target="' + $this.data('target') + '"]', owner.$EPGCOntainerId).addClass('active').siblings('div').removeClass('active');

				if ($target == 'live_now')
				{
					if (!owner.hasCheckChannelStats)
					{
						owner.hasCheckChannelStats = !owner.hasCheckChannelStats;
						toggleLoading(true);
						owner.initLiveNow();
					}
				}
				else
				{

					if (!owner.hasScheduleRender)
					{
						owner.hasScheduleRender = true;
						var __channel = $.extend(true, {}, window.g_channel)
						__channel.seoAlias = getChannelSeoAlias(__channel);
						owner.initEpg(__channel);
					}
				}

			})
			this.closeEPGContainer();
			$('.epg-header-content div:eq(0)', owner.$EPGContainerId).click();
		}

		this.closeEPGContainer = function ()
		{
			$('#epg-container .close').on('click', function ()
			{
				owner.hide();
			})
		}

		this.show = function ()
		{
			if (!this.hasEPGRender)
			{
				this.renderEPGContainer();
			}
			owner.$containerId.show();
			owner.epgModal && owner.epgModal.epgListModal && owner.epgModal.epgListModal.scrollToPlayingItem && owner.epgModal.epgListModal.scrollToPlayingItem();
		}

		this.hide = function ()
		{
			owner.$containerId.hide();
		}

		this.handleSlotId = function (id)
		{
			var epgItem = owner.epgModal.epgListModal.getEPGItemBySlot(id);
			var newProgram = {};
			if (epgItem)
			{
				newProgram.epgShowName = epgItem.e;
				newProgram.bigImage =  !!epgItem.img ? (/^http/gi.test(epgItem.img) ?  epgItem.img : LOC_CONTENT + 'epg/' + epgItem.img) :
						!! epgItem.image ? (/^http/gi.test(epgItem.image) ? epgItem.image : LOC_CONTENT + 'epg/' + epgItem.image) : (LOC_CONTENT + 'channels/' + owner.epgModal._channel.id + '_eb.jpg');
				window.singlePlayer.updateLastProgram && window.singlePlayer.updateLastProgram(newProgram);
				owner.epgModal.epgListModal.updateLiveProgram(id);
			}
		}

		function toggleLoading(isShow)
		{
			$('.loading', owner.$EPGContainerId).toggle(isShow);
		}

		function findItemByArray(attr, arrayAttr, array)
		{
			return array.filter(function (item)
			{
				return item[arrayAttr].toLowerCase() === attr.toLowerCase()
			})[0];
		}

		window.liveCompleteHandle = function ()
		{
			var epgItem = owner.epgModal.epgListModal.getNextEPGItem();
			window.autoSwitchEPG = epgItem;
			owner.epgModal.playChannelEpg(epgItem, true,null,true);
			var newProgram = {}
			newProgram.date = epgItem.sl;
			newProgram.beginDateTimeGMT = epgItem.su;
			newProgram.nextDateTime = epgItem.el;
			newProgram.nextDateTimeGMT = epgItem.eu;
			newProgram.epgShowName = epgItem.e || '';
			if (epgItem.progId)
			{
				newProgram.progId = epgItem.progId
			}
			if (newProgram.bigImage)
			{
				newProgram.bigImage = LOC_CONTENT + newProgram.bigImage;
			}
			else
			{

				_image = !!epgItem.img ? (/^http/gi.test(epgItem.img) ?  epgItem.img : LOC_CONTENT + 'epg/' + epgItem.img) :
											!! epgItem.image ? (/^http/gi.test(epgItem.image) ? epgItem.image : LOC_CONTENT + 'epg/' + epgItem.image) : (LOC_CONTENT + 'channels/' + owner.epgModal._channel.id + '_eb.jpg');
				newProgram.bigImage = _image;
			}
			window.singlePlayer.updateLastProgram && window.singlePlayer.updateLastProgram(newProgram);
			owner.epgModal && owner.epgModal.epgListModal && owner.epgModal.epgListModal.playNext(true);
		}
		window.programCompleteCallbackHandle = function ()
		{
			var epgItem = owner.epgModal.epgListModal.getNextEPGItem();
			window.autoSwitchEPG = epgItem;
			owner.epgModal && owner.epgModal.epgListModal && owner.epgModal.epgListModal.playNext && owner.epgModal.epgListModal.playNext();
		}
		window.nlJumpToLiveProgramCallback = function ()
		{
			owner.epgModal && owner.epgModal.epgListModal && owner.epgModal.epgListModal.goLive && owner.epgModal.epgListModal.goLive();
		}
		window.switchChannel = function (seoname)
		{
			window.lastPlayingProgram = null;
			seoname = seoname.toLowerCase();
			checkChannelAccess(seoname, function (hasAccess, channel)
			{
				if (hasAccess && channel)
				{
					//Support Free Sample count down
					if (window.isTVE && userType == 'anonymous') {
						_FreeSample && _FreeSample.check(true);
					}
					if (window.history.state && window.history.state.seo_name !== seoname)
					{
						window.history.replaceState({type: 'channelPlayer', seo_name: seoname},'', setURLParam('seoName',seoname));
					}
					var $wrap = $('#epg-current-now .program[data-seoname="' + seoname + '"]');
					$wrap.addClass('is-playing').parent('.owl-item').siblings('.owl-item ').find('.program').removeClass('is-playing');

					var _currentDate = +g_currentDate + new Date().getTime() - window.defaultTimeZoneOffset;
					channel.date = new Date(window.moment.tz(NEU.util.parseDate(window.moment(_currentDate).format("YYYY-MM-DDTHH:mm:ss.SSS")), channel.timeZone).format())
					channel.timeZoneOffset = window.g_timeZoneOffset;
					if (channel.type && channel.type === 2)
					{
						_currentDate = +g_currentDateGMT + new Date().getTime() - window.defaultTimeZoneOffset;
						channel.date = new Date(window.moment.tz(NEU.util.parseDate(window.moment(_currentDate).format("YYYY-MM-DDTHH:mm:ss.SSS")), 'GMT').format())
						// window.g_timeZoneOffset = new Date() - new Date(window.moment(_currentDate).format())
					}
					channel.seoAlias = getChannelSeoAlias(channel);
					owner.initEpg(channel);
					window.channelInfoUpdate && window.channelInfoUpdate(channel);
				}
			})
		}
		this.renderEPGContainer()
	}

	return PlayerEPG;
}));

window.lastPlayingProgram=null;
window.autoSwitchEPG = null;

function playPreAuthEpg()
{
	if (window.lastPlayingProgram)
	{
		var lastPlayingDate = window.lastPlayingProgram.data_date;
		var lastPlayingIndex = window.lastPlayingProgram.data_index;
		var lastEpgItemTitle = $("#scheduleList .program[data-date='" + lastPlayingDate + "'][data-index='" + lastPlayingIndex + "'] .program__title");
		triggerEvent(lastEpgItemTitle[0], 'mousedown', null, 1);
		triggerEvent(lastEpgItemTitle[0], 'mouseup', null, 1);
	}
}

function triggerEvent(element, eventName, eventData, which)
{
	var event = null;
	if (window.CustomEvent)
	{
		if (typeof window.CustomEvent !== "function")
		{
			var innerCustomEvent = function (event, params) {
				params = params || {bubbles: false, cancelable: false, detail: undefined};
				var evt = document.createEvent('CustomEvent');
				evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
				return evt;
			};
			innerCustomEvent.prototype = window.Event.prototype;
			window.CustomEvent = innerCustomEvent;
		}
		event = new CustomEvent(eventName, {detail: eventData});
	}
	else
	{
		event = document.createEvent('CustomEvent');
		event.initCustomEvent(eventName, true, true, eventData);
	}
	if (which !== undefined)
	{
		event.which = which;
	}
	element.dispatchEvent(event);
}

