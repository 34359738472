/**
 * playlistEpg Plugin
 * DVR+live+Upcoming
 * dependency: owlCarousel
 */

(function () {

	var currentPlaylistEPGDataList = {};//current all program list：contains dvr,live,upcoming  (value will change when 1. reload feed 2. live changed)
	var isLive = true; //true:live ; false:dvr  (a boolean flag denotes that the playing program is dvr or live)
	var epgInterval = null;// use when there is only one program item.
	var playlistepgInterval = null;//refresh feed
	var currentCuePoint = null;

	// Playlist EPG
	var PlayListEPG = function (element, options) {
		this.epgListElement = getElement(element);

		this.isFirstLoad = true;
		this.isFirstRender = true;
		this.epgFeedCache = null;
		this.epgEndTime = '';
		this.lastPlaylist = []; //last Rotation Program list ( which contains 'startTime' key)
		this.currentPlaylist = [];//current Rotation Program list
		this.hasPlayedProgramList = [];
		this.isPresentRotationFinish = false;
		this.liveEPGItemIndex = null;// current live program Index
		this.playingEPGItemIndex = null;//current playing program Index
		this.playingEPGItem = null; // current playing program

		this.options = deepExtend({}, PlayListEPG.options, options);
		this.defaultOptions = PlayListEPG.DEFAULTS;	// FIXME shared
		this.config = PlayListEPG.config;	// FIXME shared

		var owner = this;

		this._initEPGFrame();

		// init load feed
		this._loadPlayListEPGFeed(function (data) {
			owner._handlePlayListEPGFeed(data);
		});

		//refresh reload feed
		this.refreshRotationProgramList();
	};

	PlayListEPG.DEFAULTS = {
		liveProgramIndex: null,//
		dvrProgramList: [],// drv
		liveProgramList: [], //live
		upcompingProgramList: [], // upcoming
		playListEPGList: [] // epg list
	};

	PlayListEPG.options = {
		playListEPGFeed: null,
		refreshStateIntervalDelay: 60000, // 10*60*1000 reload feed interval time
		egpFeedTimeout: 3000,
		classes: {
			listWrapper: 'epg-list',
			listItem: 'program', // default
			clickableItemClass: 'program', //for bind click event
			state: {
				itemDvr: 'is-dvr',
				itemLive: 'is-live',
				itemUpcoming: 'is-upcoming',
				itemPlaying: 'is-playing',
				itemArchive: 'is-archive',
				itemLiveNext: 'next-program'
			},
		},
		server: {
			clientOffsetTime: 0 //*  clientTime - serverTime
		},
		template: {
			epgFrameTemplate: '', // Frame
			epgFrameData: null, // *
			epgItemTemplate: '',  //Item
			epgItemData: null	//funciton: handle ItemData for the epgItemTemplate	//FIXME function defined in template object?
		},
		callback: {
			playProgram: null,// play program
		},
		player: {
			isReady: null,
		}
	}

	PlayListEPG.config = {
		epgConfig: {
			epgListElement: null,
			$epgCarousel: null
		}
	}

	PlayListEPG.prototype._initEPGFrame = function () {
		var template = this.options.template;
		this.epgListElement.innerHTML = initTemplate(template.epgFrameTemplate, template.epgFrameData);
		this.config.epgConfig.epgListElement = this.epgListElement.querySelector('.' + this.options.classes.listWrapper);
	};

	// load playlist EPG feed
	PlayListEPG.prototype._loadPlayListEPGFeed = function (callback) {
		var that = this;
		var epgUrl = this.options.playListEPGFeed;
		if (epgUrl == '' || epgUrl == null)
		{
			console.log('playListEPGFeed must be set!');
			return;
		}
		var failed = false;
		var iid = window.setTimeout(function () {
			failed = true;
			callback && callback([]);
		}, that.options.egpFeedTimeout);

		NEU.util.getJSON(NEU.util.addTimestamp(epgUrl, 60), function (data) {
			if (!failed)
			{
				window.clearTimeout(iid);
				if (that.epgFeedCache == '' || (that.epgFeedCache != '' && that.epgFeedCache != JSON.stringify(data)))
				{
					that.isFirstRender = true;
					that.epgFeedCache = JSON.stringify(data);
					callback && callback(data);
				}
			}
		});
	};

	// handle the playlist EPG feed
	PlayListEPG.prototype._handlePlayListEPGFeed = function (data) {
		var that = this;
		that.currentPlaylist = [];
		that.lastPlaylist = [];
		if (data.length == 0 )
		{
			if(that.isFirstLoad){
				that._playProgramWhenPlayerReady(null);
				that.isFirstLoad = false;
			}
		}
		else
		{
			var epgObj = data[0];
			var epgList = epgObj.items;
			//Step 1.Get lastPlaylist & currentPlaylist
			epgList.forEach(function (item) {
				var startTime = item.startTime;
				if (startTime == null || startTime == 'undefined')
				{
					that.currentPlaylist.push(item);
				}
				else
				{
					that.lastPlaylist.push(item);
				}
			});

			//Step 2. Store the end time
			that.epgEndTime = getEndTime(epgObj.startTime, epgObj.total, 's');
			//Step 3. loadEpgList
			if (that.currentPlaylist.length > 0)
			{
				if (that.isFirstLoad)
				{
					var epgItem = that.currentPlaylist[0];
					that._playProgramWhenPlayerReady(epgItem);
					that.isFirstLoad = false;
				}
				that._loadEPGList();
			}

			//Scenario: currentPlaylist.length is only one
			if (that.currentPlaylist.length == 1)
			{
				if (epgInterval != null)
				{
					clearInterval(epgInterval);
					epgInterval = null;
				}
				epgInterval = setInterval(function () {
					that._checkPresentRotationFinished();
					if (that.isPresentRotationFinish)
					{
						var slot = that.currentPlaylist[0].slot;
						that.updateLiveProgram(slot);
						clearInterval(epgInterval);
						epgInterval = null;
					}
				}, 1000);
			}
		}

	}

	// loadEPGList
	PlayListEPG.prototype._loadEPGList = function () {
		//Step 1. Get inital live program
		var index = this.currentPlaylist[0].i;
		this._setLiveProgramIndex(index);

		//Step 2. Check has already finish first Rotation
		this._checkPresentRotationFinished();

		//Step 3. Get hasPlayedProgramList=[]
		this._updatePlayedProgramList();

		//Step 4. Update playlist
		this.updatePlayListEPGData();// generate new PlaylistEPGData ,and then render & bindEvent

		//Step 5. Set live now and playing now
		isLive = true;//live
		var index = this.defaultOptions.dvrProgramList.length;
		this.liveEPGItemIndex = index;
		this.playingEPGItemIndex = index;
		this.playingEPGItem = currentPlaylistEPGDataList[index];

		// Scroll at the first time
		if (this.isFirstRender)
		{
			this.scrollToItem(this.liveEPGItemIndex);
			this.isFirstRender = false;
		}

		//Step 6. Update Item status
		this._updateItemClassStatus(this.playingEPGItemIndex, 'itemPlaying');

		//Step 7. Use last cuepoint or wait for next cuepoint
		if(currentCuePoint){
			this.updateLiveProgram(currentCuePoint);
		}
	}

	/**
	 *  update playList EPG data
	 * Two conditions ：1. reload Feed  2.live change
	 * compute by following: lastPlaylist,currentPlaylist,hasPlayedProgramList(isFinished), liveId
	 * Redraw & bindEvent when update
	 */
	PlayListEPG.prototype.updatePlayListEPGData = function () {
		var that = this;
		that.defaultOptions.playListEPGList = [];

		//Set DVR & Live & Upcoming EPG Data
		that._setDVREPGData();
		that._setLiveEPGData();
		that._setUpcomingEPGData();

		//Add status to each program
		addProgramStatus(that.defaultOptions.dvrProgramList, that.options.classes.state.itemDvr);
		addProgramStatus(that.defaultOptions.liveProgramList, that.options.classes.state.itemLive);
		addProgramStatus(that.defaultOptions.upcompingProgramList, that.options.classes.state.itemUpcoming);

		//Add up-next status
		if (that.defaultOptions.upcompingProgramList.length > 0)
		{
			that.defaultOptions.upcompingProgramList[0].status += ' ' + that.options.classes.state.itemLiveNext;
		}

		that.defaultOptions.playListEPGList = [].concat(that.defaultOptions.dvrProgramList, that.defaultOptions.liveProgramList, that.defaultOptions.upcompingProgramList);

		for (var i = 0; i < that.defaultOptions.playListEPGList.length; i++)
		{
			var epgItem = that.defaultOptions.playListEPGList[i];
			currentPlaylistEPGDataList[i] = epgItem;
		}

		//unbind click event
		that.bindDVRItemClickEvent(false);

		that._renderPlayListEPGList(that.defaultOptions.playListEPGList);

		//bind click event
		that.bindDVRItemClickEvent(true);

	}

	PlayListEPG.prototype.updatePlayListDom = function () {
		var that = this;

		//Set DVR & Live & Upcoming EPG Data
		that._updateDVREPGData();
		that._setLiveEPGData();
		that._setUpcomingEPGData();

		//Add status to each program
		addProgramStatus(that.defaultOptions.dvrProgramList, that.options.classes.state.itemDvr);
		addProgramStatus(that.defaultOptions.liveProgramList, that.options.classes.state.itemLive);
		addProgramStatus(that.defaultOptions.upcompingProgramList, that.options.classes.state.itemUpcoming);

		//Add up-next status
		if (that.defaultOptions.upcompingProgramList.length > 0)
		{
			that.defaultOptions.upcompingProgramList[0].status += ' ' + that.options.classes.state.itemLiveNext;
		}

		that.defaultOptions.playListEPGList = [].concat(that.defaultOptions.dvrProgramList, that.defaultOptions.liveProgramList, that.defaultOptions.upcompingProgramList);

		for (var i = 0; i < that.defaultOptions.playListEPGList.length; i++)
		{
			var epgItem = that.defaultOptions.playListEPGList[i];
			currentPlaylistEPGDataList[i] = epgItem;
		}
	}

	// render EPG UI
	PlayListEPG.prototype._renderPlayListEPGList = function (epgItems) {
		//clear
		this.config.epgConfig.epgListElement.innerHTML = '';
		if (this.config.epgConfig.$epgCarousel)
		{
			this.config.epgConfig.$epgCarousel.trigger('destroy.owl.carousel');
			this.config.epgConfig.$epgCarousel = null;
		}

		var listDomArray = [],
				epgItemTemplate = this.options.template.epgItemTemplate,
				elementWrapper = document.createElement('div'),
				that = this;
		for (var i = 0; i < epgItems.length; i++)
		{
			var epgItemData = this.options.template.epgItemData(epgItems[i]);
			elementWrapper.innerHTML = initTemplate(epgItemTemplate, epgItemData, epgItems[i]);
			var itemElement = this._processItemAttribute(elementWrapper.firstChild, epgItems[i]);
			listDomArray[i] = itemElement.outerHTML;
		}

		that.config.epgConfig.epgListElement.innerHTML = listDomArray.join('');

		renderCarouselList();

		function renderCarouselList()
		{
			if (!that.config.epgConfig.$epgCarousel)
			{
				var initRefresh = false;

				that.config.epgConfig.$epgCarousel = $(that.config.epgConfig.epgListElement)
						.addClass('owl-carousel')
						.owlCarousel(that.options.epgConfig.carouselConfig);

				// Process Live is in first/last page, then get the prev/next epg list;
				setTimeout(function () {
					initRefresh = true;
					that.config.epgConfig.$epgCarousel.trigger('refresh.owl.carousel');
				}, 500);
			}
			else
			{
				that.config.epgConfig.$epgCarousel.trigger('refresh.owl.carousel');
			}
		};
	}

	PlayListEPG.prototype._processItemAttribute = function (itemElement, epgData) {
		itemElement.setAttribute('data-programid', epgData.i);
		itemElement.setAttribute('data-spid', epgData.spid);
		itemElement.setAttribute('data-slot', epgData.slot);
		addClass(itemElement, epgData.status);
		return itemElement;
	};

	// Init scroll to live item
	PlayListEPG.prototype.scrollToItem = function (index) {
		if (index == null)
		{
			return;
		}
		this.config.epgConfig.$epgCarousel.trigger('to.owl.carousel', [index, 200, true]);
	};

	PlayListEPG.prototype._playProgramWhenPlayerReady = function (epgItem) {
		var times = 600, timeout = 100, that = this; // 600times and 0.1s
		checkPlayerIsReady();

		function checkPlayerIsReady()
		{
			if (window[that.options.player.isReady])
			{
				window.setTimeout(function () {
					that.options.callback.playProgram && that.options.callback.playProgram(JSON.parse(JSON.stringify(epgItem)), true, true);//true denotes playlist epg
				}, 0);
			}
			else
			{
				if (--times)
				{
					window.setTimeout(checkPlayerIsReady, timeout);
				}
			}
		}
	};

	PlayListEPG.prototype.getEPGItemBySlot = function (cuePoint) {
		var nextEpgItem = null;
		for (var index = 0; index < this.currentPlaylist.length; index++)
		{
			if (this.currentPlaylist[index].slot == cuePoint)
			{
				nextEpgItem = this.currentPlaylist[index];
				break;
			}
		}
		return JSON.parse(JSON.stringify(nextEpgItem));
	}

	// Get next EPG Item
	PlayListEPG.prototype.getNextEPGItem = function (isLive) {
		var nextEPGItemIndex = null;
		if (isLive)
		{//live completed
			if (this.currentPlaylist.length == 1)
			{
				nextEPGItemIndex = this.liveEPGItemIndex;
			}
			else
			{
				nextEPGItemIndex = parseInt(this.liveEPGItemIndex) + 1;
			}
			//at the same time ,updateLiveProgram
			var nextEpgItem = currentPlaylistEPGDataList[nextEPGItemIndex];

			//return
			return JSON.parse(JSON.stringify(nextEpgItem));
		}
		else//dvr completed
		{
			return null;
		}
	};


	//  play next vod
	PlayListEPG.prototype.playNext = function () {
		if (isLive)
		{//if has changed to live,do nothing
			return null;
		}
		var nextEPGItemIndex = parseInt(this.playingEPGItemIndex) + 1;

		if (nextEPGItemIndex == this.defaultOptions.dvrProgramList.length)
		{
			isLive = true;//playing live
			this.playingEPGItemIndex = this.liveEPGItemIndex;
			this.playingEPGItem = currentPlaylistEPGDataList[this.playingEPGItemIndex];
		}
		else
		{
			this.playingEPGItemIndex = nextEPGItemIndex;
			this.playingEPGItem = currentPlaylistEPGDataList[nextEPGItemIndex];
		}
		//update class status
		this._updateItemClassStatus(this.playingEPGItemIndex, 'itemPlaying');

		//callback player
		this.options.callback.playProgram && this.options.callback.playProgram(JSON.parse(JSON.stringify(this.playingEPGItem)), isLive, true);
	}

	//binding click event for DVR Items
	PlayListEPG.prototype.bindDVRItemClickEvent = function (isBind) {
		var that = this,
				epgListItems = that.epgListElement.querySelectorAll('.' + that.options.classes.listItem),
				epgListLength = epgListItems.length;

		if (epgListLength > 0)
		{
			var clickableItemClasses = that.options.epgConfig.clickableItemSubClasses;
			if (clickableItemClasses && clickableItemClasses.length > 0)
			{
				clickableItemClasses.forEach(function (clickableClass) {
					epgListItems.forEach(function (element) {
						bindClick(element.querySelectorAll('.' + clickableClass), epgClickedHandler, isBind);
					});
				})
			}
			else
			{
				// Default epg item element
				bindClick(epgListItems, epgClickedHandler, isBind);
			}
		}

		function epgClickedHandler(event)
		{
			var itemElement = closest(event.target, that.options.classes.listItem);
			var isDvrItem = hasClass(itemElement, that.options.classes.state.itemArchive);
			var elem = itemElement.getAttribute('data-slot');
			if (isDvrItem)
			{
				var index = indexOfArrayBySlot(elem, that.defaultOptions.dvrProgramList, 0);
				if (!isLive && parseInt(index) === parseInt(that.playingEPGItemIndex))// playing is playing
				{
					return;
				}
				isLive = false; //playing dvr
				that.playingEPGItemIndex = index;
				that.playingEPGItem = currentPlaylistEPGDataList[index];
			}
			else
			{
				if (isLive)
				{
					return;
				}
				isLive = true;//playing live
				that.playingEPGItemIndex = that.liveEPGItemIndex;
				that.playingEPGItem = currentPlaylistEPGDataList[that.playingEPGItemIndex];
			}

			that._updateItemClassStatus(that.playingEPGItemIndex, 'itemPlaying');

			var epgItem = currentPlaylistEPGDataList[that.playingEPGItemIndex];//return current playing program item
			that.options.callback.playProgram && that.options.callback.playProgram(JSON.parse(JSON.stringify(epgItem)), isLive, true);

		}

		function bindClick(elements, callback, isBind)
		{
			var mouseDownX = 0, mouseDownY = 0;
			if (isBind)
			{
				[].forEach.call(elements, function (element) {
					element.addEventListener("mousedown", mouseDownHandler, false);
				});
			}
			else     //isBind=false
			{
				[].forEach.call(elements, function (element) {
					element.removeEventListener("mousedown", mouseDownHandler, false);
					element.removeEventListener("mousemove", mouseMoveHandler, false);
					element.removeEventListener("mouseup", mouseUpHandler, false);
				});
			}

			function mouseDownHandler(event)
			{
				mouseDownX = event.pageX;
				mouseDownY = event.pageY;
				event.target.addEventListener("mousemove", mouseMoveHandler, false);
				event.target.addEventListener("mouseup", mouseUpHandler, false);
			}

			function mouseMoveHandler(event)
			{
				if (Math.abs(event.pageX - mouseDownX) + Math.abs(event.pageY - mouseDownY) === 0)
				{
					return false;
				}
				else
				{
					event.target.removeEventListener("mousemove", mouseMoveHandler, false);
					event.target.removeEventListener("mouseup", mouseUpHandler, false);
				}
			}

			function mouseUpHandler(event)
			{
				callback && callback(event);
				event.target.removeEventListener("mousemove", mouseMoveHandler, false);
				event.target.removeEventListener("mouseup", mouseUpHandler, false);
			}
		}
	}

	// Check presentRotationPrograms is or not finished . If currentTime>=EPG.startTime+EPG.total , finished
	PlayListEPG.prototype._checkPresentRotationFinished = function () {
		var endTime = this.epgEndTime;
		var currentTime = this._getCurrentTime();
		this.isPresentRotationFinish = (endTime > currentTime ? false : true);
	}

	PlayListEPG.prototype._getCurrentTime = function () {
		return (new Date()).getTime() - this.options.server.clientOffsetTime;
	}

	// Get played Program Items
	PlayListEPG.prototype._updatePlayedProgramList = function () {
		if (this.isPresentRotationFinish)
		{
			var index = indexOfArray(this.defaultOptions.liveProgramIndex, this.currentPlaylist, 0);
			this.hasPlayedProgramList = copy([].concat(this.currentPlaylist.slice(index), this.currentPlaylist.slice(0, index)), true);
		}
		else
		{
			var index = indexOfArray(this.defaultOptions.liveProgramIndex, this.currentPlaylist, 0);
			this.hasPlayedProgramList = copy(this.currentPlaylist.slice(0, index), true);
		}
	}

	// update live Program Id
	PlayListEPG.prototype._setLiveProgramIndex = function (elem) {
		this.defaultOptions.liveProgramIndex = elem;
	}

	// Handle setup is-live,is-playing,up-next class for item
	PlayListEPG.prototype._updateItemClassStatus = function (currentIndex, classType) {
		if (currentIndex < 0)
		{
			return;
		}
		var epgListElement = this.config.epgConfig.epgListElement;
		var epgItem = this.options.classes.listItem;
		var className = this.options.classes.state[classType];
		var isUpcoming = this.options.classes.state.itemUpcoming;

		// 1. Remove exist
		var existItem = epgListElement.querySelectorAll('.' + className);


		for (var i = 0; i < existItem.length; i++)
		{
			removeClass(existItem[i], className);
		}


		// 2. Add current
		var epgProgramList = epgListElement.querySelectorAll('.' + epgItem);
		if (classType == 'itemLive' || classType == 'itemPlaying')
		{
			removeClass(epgProgramList[currentIndex], isUpcoming);
		}
		addClass(epgProgramList[currentIndex], className);
	}

	// set DVR data array
	PlayListEPG.prototype._setDVREPGData = function () {
		var that = this;
		that.defaultOptions.dvrProgramList = []; //clear
		var maxDvrLength = that.currentPlaylist.length;
		that.defaultOptions.dvrProgramList = copy(that.lastPlaylist.concat(that.hasPlayedProgramList).slice(-maxDvrLength));
	}

	PlayListEPG.prototype._updateDVREPGData = function () {
		var that = this;
		var lastDvr = that.defaultOptions.dvrProgramList.length == 0 ? that.lastPlaylist : that.defaultOptions.dvrProgramList;
		var maxDvrLength = that.currentPlaylist.length;
		if (!that.isPresentRotationFinish)
		{
			var index = indexOfArray(that.defaultOptions.liveProgramIndex, that.defaultOptions.playListEPGList, that.defaultOptions.dvrProgramList.length);
			that.defaultOptions.dvrProgramList = copy([].concat(this.defaultOptions.playListEPGList.slice(0, index).slice(-maxDvrLength)));
		}
		else
		{
			that.defaultOptions.dvrProgramList = copy(lastDvr.concat(that.hasPlayedProgramList).slice(-maxDvrLength));
		}
	}


	// set Live data arry
	PlayListEPG.prototype._setLiveEPGData = function () {
		this.defaultOptions.liveProgramList = [];//clear
		var index = indexOfArray(this.defaultOptions.liveProgramIndex, this.currentPlaylist, 0);
		var elem = this.currentPlaylist[index];
		this.defaultOptions.liveProgramList[0] = copy(elem, true);
	}

	/**
	 * set upcoming data array
	 *     upcoming:
	 *         1.Get live index
	 *         2.Get programs behind the live
	 *         3.Push the hasPlayedPrograms
	 */
	PlayListEPG.prototype._setUpcomingEPGData = function () {
		var that = this;
		that.defaultOptions.upcompingProgramList = [];
		var presentPlanList = that.currentPlaylist;
		var upcomingLength = presentPlanList.length - 1;
		var liveProgramIndex = that.defaultOptions.liveProgramIndex;
		if (upcomingLength > 0)
		{//only if presentPlanList.length>1
			var index = indexOfArray(liveProgramIndex, presentPlanList, 0);

			//live program is not the last one
			that.defaultOptions.upcompingProgramList = copy(presentPlanList.slice(index + 1, presentPlanList.length), true);

			//live program is not the first one
			if (index > 0)
			{
				that.defaultOptions.upcompingProgramList = copy(that.defaultOptions.upcompingProgramList.concat(presentPlanList.slice(0, index)), true);
			}
		}
	}

	/**
	 * Re-render owl's item by add /remove
	 */
	PlayListEPG.prototype._updateEPGElementList = function (index, changedCount, sort) {
		var that = this;
		var isDvr = this.options.classes.state.itemDvr;
		var isUpcoming = this.options.classes.state.itemUpcoming;
		var isNext = this.options.classes.state.itemLiveNext;
		var isLive = this.options.classes.state.itemLive;
		var isPlaying = this.options.classes.state.itemPlaying;

		// Step 1: update the index item be is-live and is-playing ,and both classes
		that._updateItemClassStatus(index, 'itemLive');
		that._updateItemClassStatus(index, 'itemPlaying');

		// Step 2: add the changed items to the end of owl
		var epgListElement = this.config.epgConfig.epgListElement;
		var epgItem = this.options.classes.listItem;
		var epgProgramList = epgListElement.querySelectorAll('.' + epgItem);
		var addCount = changedCount;
		var maxDvrLength = that.currentPlaylist.length * 2;
		var removeCount = epgProgramList.length + addCount - maxDvrLength;

		for (var i = index - changedCount; i < index; i++)
		{
			// A).Clone
			var newDom = epgProgramList[i].cloneNode(true);

			// B).Set the changed item to be dvr
			removeClass(epgProgramList[i], isUpcoming);
			addClass(epgProgramList[i], isDvr);

			// C).Create new item and append
			addClass(newDom, isUpcoming);
			var domItem = newDom.outerHTML;
			that.config.epgConfig.$epgCarousel.owlCarousel('add', domItem).owlCarousel('update');
		}

		// Step 3: Setup up-next item
		if (changedCount > 0)
		{
			that._updateItemClassStatus(index + 1, 'itemLiveNext');
		}
		else
		{
			that._updateItemClassStatus(-1, 'itemLiveNext');
		}

		// Step 4. Handle front items of owl
		if (that.isPresentRotationFinish)
		{
			if (removeCount > 0)// Remove
			{
				for (var i = removeCount; i > 0; i--)
				{
					that.config.epgConfig.$epgCarousel.trigger('remove.owl.carousel', [0]);
				}
			}
			else if (removeCount < 0) //Add
			{
				var nowDvrCount = that.currentPlaylist.length + removeCount;
				//1.remove all dvr
				for (var i = 0; i < nowDvrCount; i++)
				{
					that.config.epgConfig.$epgCarousel.trigger('remove.owl.carousel', [0]);
				}

				//2. copy live and upcoming
				epgProgramList = epgListElement.querySelectorAll('.' + epgItem);
				for (var i = epgProgramList.length - 1; i >= 0; i--)
				{
					var newDom = epgProgramList[i].cloneNode(true);

					// B).Set the changed item to be dvr
					removeClass(newDom, isLive);
					removeClass(newDom, isUpcoming);
					removeClass(newDom, isNext);
					removeClass(newDom, isPlaying);
					addClass(newDom, isDvr);
					var domItem = newDom.outerHTML;
					that.config.epgConfig.$epgCarousel.trigger('add.owl.carousel', [domItem, 0])
							.trigger('refresh.owl.carousel');
				}
			}
			else
			{
				// Special case: just one program
				if (that.currentPlaylist.length == 1)
				{
					//1.delete the dvr
					that.config.epgConfig.$epgCarousel.trigger('remove.owl.carousel', [0]);

					//2.add the dvr
					var length = epgProgramList.length;
					var newDom = epgProgramList[length - 1].cloneNode(true);
					removeClass(newDom, isLive);
					removeClass(newDom, isPlaying);
					addClass(newDom, isDvr);
					var domItem = newDom.outerHTML;

					that.config.epgConfig.$epgCarousel.trigger('add.owl.carousel', [domItem, 0])
							.trigger('refresh.owl.carousel');

				}
			}
		}
		else
		{
			for (var i = 0; i < removeCount; i++)
			{
				that.config.epgConfig.$epgCarousel.trigger('remove.owl.carousel', [0]);
			}
		}
	}

	/**
	 *  update live program by Player cue point
	 */
	PlayListEPG.prototype.updateLiveProgram = function (elem) {
		var that = this;
		var programIndex = null;
		var startIndex = that.defaultOptions.dvrProgramList.length;// start from  presentRotation
		for (var index = startIndex; index < that.defaultOptions.playListEPGList.length; index++)
		{
			if (that.defaultOptions.playListEPGList[index].slot == elem)
			{
				programIndex = that.defaultOptions.playListEPGList[index].i;
				break;
			}
		}
		if (programIndex == null)
		{
			return;
		}

		var changedCount = index - startIndex;

		this._checkPresentRotationFinished();

		if (!this.isPresentRotationFinish)
		{
			for (var sort = 0; sort < that.currentPlaylist.length; sort++)
			{
				if (that.currentPlaylist[sort].slot == elem)
				{
					break;
				}
			}
		}

		that.bindDVRItemClickEvent(false);

		that._updateEPGElementList(index, changedCount, sort);

		that.bindDVRItemClickEvent(true);

		// Pre-process Data
		this._setLiveProgramIndex(programIndex);  //programId

		this._updatePlayedProgramList();

		// Process Data , generate playlist
		this.updatePlayListDom();

		// Update status
		isLive = true;
		this.liveEPGItemIndex = this.defaultOptions.dvrProgramList.length;//init
		this.playingEPGItemIndex = this.liveEPGItemIndex;

		// Scroll at the first time
		if (this.isFirstRender)
		{
			this.scrollToItem(this.liveEPGItemIndex);
			this.isFirstRender = false;
		}
	}

	// polling to reload
	PlayListEPG.prototype.refreshRotationProgramList = function () {
		var that = this;
		if (playlistepgInterval != null)
		{
			clearInterval(playlistepgInterval);
			playlistepgInterval = null;
		}

		playlistepgInterval = window.setInterval(function () {
			that._loadPlayListEPGFeed(function (data) {
				that._handlePlayListEPGFeed(data);
			});
		}, that.options.refreshStateIntervalDelay);
	}


	/*--------------------------------------helper functions-----------------------------------------*/
	function hasClass(element, className)
	{
		if (element.classList)
			return element.classList.contains(className);
		else
			return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
	}

	function findParent(element, selector)
	{
		while ((element = element.parentElement) && !matches(element, selector))
		{
		}
		return element;
	}

	function matches(el, selector)
	{
		return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
	}

	function closest(element, className)
	{
		var closestElement = null;
		if (hasClass(element, className))
		{
			closestElement = element;
		}
		else
		{
			closestElement = findParent(element, '.' + className);
		}
		return closestElement;
	}

	function addClass(element, className)
	{
		if (className.indexOf(' ') >= 0)
		{
			className.split(' ').forEach(function (item) {
				if (element.classList)
				{
					element.classList.add(item);
				}
				else
				{
					element.className += ' ' + item;
				}

			});
		}
		else
		{
			if (element.classList)
			{
				element.classList.add(className);
			}
			else
			{
				element.className += ' ' + className;
			}
		}
	}

	function removeClass(element, className)
	{
		if (element.classList)
			element.classList.remove(className);
		else
			element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	}

	function getElement(element)
	{
		var resultElement = null;
		if (element.jquery)
		{
			element = element.length > 1 ? element.get() : element[0];
		}
		if (NodeList.prototype.isPrototypeOf(element) || Array.isArray(element))
		{
			resultElement = element[0];
		}
		else if (element.nodeType)
		{
			resultElement = element;
		}
		return resultElement;
	}

	// Get elem index in array start by startIndex
	function indexOfArray(elem, objArray, startIndex)
	{
		if (startIndex == null || startIndex == 'undefined')
		{
			startIndex = 0;
		}
		var start = parseInt(startIndex);
		for (var index = start; index < objArray.length; index++)
		{
			if (elem == objArray[index].i)
			{
				break;
			}
		}
		if (index == objArray.length)
		{
			return -1;
		}
		else
		{
			return index;
		}

	}

	function indexOfArrayBySlot(elem, objArray, startIndex)
	{
		if (startIndex == null || startIndex == 'undefined')
		{
			startIndex = 0;
		}
		var start = parseInt(startIndex);
		for (var index = start; index < objArray.length; index++)
		{
			if (elem == objArray[index].slot)
			{
				break;
			}
		}
		if (index == objArray.length)
		{
			return -1;
		}
		else
		{
			return index;
		}
	}

	//set status ( dvr | live | upcoming ) for each program
	function addProgramStatus(programList, status)
	{
		programList.forEach(function (item) {
			item['status'] = status;
		});

	}

	function initTemplate(template, data, sourceData)
	{
		var result = template;
		for (var key in data)
		{
			if (data.hasOwnProperty(key))
			{
				var dataValue = data[key];
				if (typeof data[key] === 'function')
				{
					dataValue = data[key](sourceData);
				}
				result = result.replace(new RegExp('{{' + key + '}}', "g"), dataValue);
			}
		}
		return result;
	}

	//compute end time
	function getEndTime(start, duration, isHMS)
	{
		var coefficient = 1;
		switch (isHMS)
		{
			case 'h':
				coefficient = 60 * 60 * 1000;
				break;
			case 'm':
				coefficient = 60 * 1000;
				break;
			case 's':
				coefficient = 1000;
				break;
			default:
				break;
		}
		var start = start.substr(0, start.length - 4);
		var startTime = (new Date(start.replace(/T/, " ").replace(/\-/g, "/")+'Z')).getTime();
		var endTime = startTime + duration * coefficient;
		return endTime;
	}


	// deep copy
	function copy(obj, deep)
	{
		//Check object
		if (obj === null || typeof obj !== "object")
		{
			return obj;
		}

		var i, target = util.isType(obj, "array") ? [] : {}, value, valueType;
		for (i in obj)
		{
			value = obj[i];
			valueType = util.getType(value);
			//object or array
			if (deep && (valueType === "array" || valueType === "object"))
			{
				target[i] = copy(value);
			}
			else
			{
				target[i] = value;
			}
		}
		return target;
	}

	//util as helper
	var util = (function () {
		var class2type = {};
		["Null", "Undefined", "Number", "Boolean", "String", "Object", "Function", "Array", "RegExp", "Date"].forEach(function (item) {
			class2type["[object " + item + "]"] = item.toLowerCase();
		})

		function isType(obj, type)
		{
			return getType(obj) === type;
		}

		function getType(obj)
		{
			return class2type[Object.prototype.toString.call(obj)] || "object";
		}

		return {
			isType: isType,
			getType: getType
		}
	})();

	// Function: deep extend
	function deepExtend(out) // arguments: (source, source1, source2, ...)
	{
		out = out || {};

		for (var i = 1; i < arguments.length; i++)
		{
			var obj = arguments[i];

			if (!obj)
				continue;

			for (var key in obj)
			{
				if (obj.hasOwnProperty(key))
				{
					if (typeof obj[key] === 'object'
							&& obj[key] !== null
							&& !Array.isArray(obj[key])
							&& !(obj[key] instanceof Date)
							&& !(obj[key] === 'function'))
					{
						out[key] = arguments.callee(out[key], obj[key]);
					}
					else
						out[key] = obj[key];
				}
			}
		}
		return out;
	}

	/*--------------------------------------helper functions  Over-----------------------------------------*/
	window.PlayListEPG = function () {
		return function (element, options) {
			var playlistEPG = new PlayListEPG(element, options);
			this.updateLiveProgram = function (cuePoint) { // for player cuePoint
				currentCuePoint=cuePoint;
				playlistEPG.updateLiveProgram(cuePoint);
			};
			this.getNextEPGItem = function (isLive) {
				return playlistEPG.getNextEPGItem(isLive);
			};
			this.getEPGItemBySlot = function (cuePoint) {
				return playlistEPG.getEPGItemBySlot(cuePoint);
			}
			this.playNext = function () {
				playlistEPG.playNext();
			};
		};
	}();
})(window);






